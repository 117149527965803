import React, { useContext } from "react";
import { DeltaContext } from "../../Contexts/DeltaContext";
import Account from "../../Assets/Icons/utilIcons/Account.svg";
function MiDelta() {
  const {
    setShowOptionMenu,
    setSelectedOption,
    showOptionMenu,
    selectedOption,
    setShowSubMenu,
    setSelectedItem,
    useActive,
  } = useContext(DeltaContext);
  return (
    <div
      className={useActive("MiDELTA") ? "active linkInternal" : "linkInternal"}
      onClick={() => {
        setShowSubMenu(false);
        setSelectedItem("");
        if (showOptionMenu && selectedOption === "MiDELTA") {
          setShowOptionMenu(false);
          setSelectedOption("");
        } else {
          setShowOptionMenu(true);
          setSelectedOption("MiDELTA");
        }
      }}
    >
      <img alt="toDelta" className="miDeltaIcon" src={Account} />
      <p> MiDELTA </p>
    </div>
  );
}

export { MiDelta };
