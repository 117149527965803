import React from "react";
import "./ResumeFrom.css";
import download from "../../../Assets/Icons/utilIcons/DownloadIcon.svg";
function ResumeForm() {
  return (
    <div>
      <div className="sectionText" style={{ borderBottom: "none" }}>
        <h2>Trabaja con nosotros</h2>
        <p>Envíanos tu información y se parte de la familia</p>
      </div>
      <div className="formContainer">
        <form
          className="resumeForm"
          id="FormularioTrabajar"
          method="post"
          action="/php/mailer/trabaja.php"
          enctype="multipart/form-data"
        >
          <label className="a" htmlFor="nombreR">
            <input
              type="name"
              id="nombreR"
              placeholder="NOMBRE"
              autoComplete="given-name"
              name="nombre"
              required
              pattern="^[a-zA-Z]*$"
            />
          </label>

          <label className="b" htmlFor="mailR">
            <input
              type="mail"
              id="mailR"
              placeholder="EMAIL"
              name="mail"
              required
              pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
            />
          </label>

          <label className="c" htmlFor="telefono">
            <input
              type="tel"
              id="telefono"
              placeholder="TELEFONO"
              name="telefono"
              required
              pattern="^[0-9+]*$"
            />
          </label>

          <label className="d" htmlFor="ciudad">
            <input
              type="text"
              id="ciudad"
              placeholder="CIUDAD"
              name="ciudad"
              required
              pattern="^[a-zA-Z\ ]*$"
            />
          </label>

          <label className="e" htmlFor="mensajeR">
            <textarea
              id="mensajeR"
              rows="3"
              col="50"
              placeholder="MENSAJE"
              name="mensaje"
              required
              pattern="^[\.a-zA-Z0-9,!? ]*$"
            ></textarea>
          </label>

          <label className="f" htmlFor="resume">
            <p>SUBE TU CV AQUÍ</p>
            <img src={download} alt="subir" />
            <div id="auxContainer">
              <input id="resume" type="file" accept=".pdf" name="curriculum" />
            </div>
          </label>
          <div className="g submitArea">
            <input type="submit" value="ENVIAR" />
          </div>
        </form>
      </div>
    </div>
  );
}

export { ResumeForm };
