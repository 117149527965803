import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const DeltaContext = React.createContext();

function DeltaProvider(props) {
  const [showMobileMenu, setShowMobileMenu] = useState(false); //Whether to show the menu on mobile

  const [showOptionMenu, setShowOptionMenu] = useState(false); //Whether to show individual menu
  const [showSubMenu, setShowSubMenu] = useState(false); //Whether to show subMenu

  const [selectedOption, setSelectedOption] = useState(""); //Selected Option, In header
  const [selectedItem, setSelectedItem] = useState(""); //selected item in the services submenu
  const [selectedContent, setSelectedContent] = useState(""); //content to be displayed in services or industries page
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  // Funcion para responder desde el render a cambios en el viewport

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [Index, setIndex] = useState(0); //Index for images, and sizes
  useEffect(() => {
    if (screenSize.width > 1150) {
      setIndex(0);
    } else if (screenSize.width > 950) {
      setIndex(1);
    } else if (screenSize.width > 600) {
      setIndex(2);
    } else {
      setIndex(3);
    }
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  // ----------------------------------------------------------

  const useActive = (menu) => {
    const location = useLocation();
    return (
      menu.toLowerCase() === location.pathname.toLowerCase().slice(1) ||
      selectedOption.toLowerCase() === menu.toLowerCase()
    );
  };
  return (
    <DeltaContext.Provider
      value={{
        showMobileMenu,
        setShowMobileMenu,
        showOptionMenu,
        setShowOptionMenu,
        showSubMenu,
        setShowSubMenu,
        selectedContent,
        selectedOption,
        setSelectedOption,
        setSelectedContent,
        selectedItem,
        setSelectedItem,
        screenSize,
        Index,
        useActive,
      }}
    >
      {props.children}
    </DeltaContext.Provider>
  );
}
// "homepage": "https://MauriMurillo.github.io/DeltaConsultLTDA",
export { DeltaContext, DeltaProvider };
