import React, { useContext } from "react";
import "./MenuList.css";
import { NavLink } from "react-router-dom";
import { DeltaContext } from "../../../Contexts/DeltaContext";
import arrow from "../../../Assets/Icons/utilIcons/Header_Arrow.svg";

function MenuList(props) {
  const { orientation } = props;
  const {
    setShowOptionMenu,
    setSelectedOption,
    showOptionMenu,
    selectedOption,
    setShowSubMenu,
    setShowMobileMenu,
    setSelectedItem,
    setSelectedContent,
    useActive
  } = useContext(DeltaContext);

  return (
    <div className={`listScreens${orientation}`}>
      <div
        className={useActive("SERVICIOS") ? "active" : ""}
        onClick={() => {
          setShowSubMenu(false);
          setSelectedItem("");
          if (showOptionMenu && selectedOption === "SERVICIOS") {
            setShowOptionMenu(false);
            setSelectedOption("");
          } else {
            setShowOptionMenu(true);
            setSelectedOption("SERVICIOS");
          }
        }}
      >
        <div>SERVICIOS</div>
        {orientation === "Vertical" ? <img src={arrow} alt="arrow" /> : null}
      </div>
      <div
        className={useActive("NOSOTROS") ? "active" : ""}
        onClick={() => {
          setShowSubMenu(false);
          setSelectedItem("");
          if (showOptionMenu && selectedOption === "NOSOTROS") {
            setShowOptionMenu(false);
            setSelectedOption("");
          } else {
            setShowOptionMenu(true);
            setSelectedOption("NOSOTROS");
          }
        }}
      >
        <div>NOSOTROS</div>
        {orientation === "Vertical" ? <img src={arrow} alt="arrow" /> : null}
      </div>
      <div
        className={useActive("INDUSTRIAS") ? "active" : ""}
        onClick={() => {
          setShowSubMenu(false);
          setSelectedItem("");
          if (showOptionMenu && selectedOption === "INDUSTRIAS") {
            setShowOptionMenu(false);
            setSelectedOption("");
          } else {
            setShowOptionMenu(true);
            setSelectedOption("INDUSTRIAS");
          }
        }}
      >
        <div>INDUSTRIAS</div>
        {orientation === "Vertical" ? <img src={arrow} alt="arrow" /> : null}
      </div>
      <div
        className={useActive("SMS") ? "active" : ""}
        onClick={() => {
          setShowSubMenu(false);
          setShowOptionMenu(false);
          setShowMobileMenu(false);
          setSelectedItem("");
          setSelectedContent("");
          setSelectedOption("SMS");
          window.scrollTo(0, 0);
        }}
      >
        <NavLink to={"/SMS"}> SMS LATAM</NavLink>
      </div>
      <div
        className={useActive("CONTACTO") ? "active" : ""}
        onClick={() => {
          setShowSubMenu(false);
          setShowOptionMenu(false);
          setShowMobileMenu(false);
          setSelectedItem("");
          setSelectedContent("");
          setSelectedOption("CONTACTO");
          window.scrollTo(0, 0);
        }}
      >
        <NavLink to={"/Contacto"}> CONTACTO</NavLink>
      </div>
    </div>
  );
}

export { MenuList };
