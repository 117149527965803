import React, { useContext } from "react";
import { Header } from "../../components/Header/Header.js";
import { Footer } from "../../components/Footer/Footer.js";
import smsLogo from "../../Assets/Logos/SMSLogo.png";
import deltaLogo from "../../Assets/Logos/ISOLOGO DELTA AZUL.png";
import forodefirmasLogo from "../../Assets/Logos/ForodeFirmas.png";

import smsPC from "../../Assets/smslatam/smslatampc.jpg";
import smsLap from "../../Assets/smslatam/smslatamlaptop.jpg";
import smsTab from "../../Assets/smslatam/smslatamtablet.jpg";
import smsCel from "../../Assets/smslatam/smslatamcelu.jpg";

import forumPC from "../../Assets/smslatam/forumpc.jpg";
import forumLap from "../../Assets/smslatam/forumlaptop.jpg";
import forumTab from "../../Assets/smslatam/forumtablet.jpg";
import forumCel from "../../Assets/smslatam/forumcelu.jpg";

import "./SMS.css";
import { DeltaContext } from "../../Contexts/DeltaContext.js";

function SMS() {
  const smsImage = [smsPC, smsLap, smsTab, smsCel];
  const forumImage = [forumPC, forumLap, forumTab, forumCel];

  const { Index } = useContext(DeltaContext);
  return (
    <div className="smsScreen Screen">
      <Header />
      <div className="smsSection">
        <div className="textContainer">
          <h1 className="Title">Red SMS</h1>
          <div className="Text">Acerca de SMS Latinoamérica</div>
        </div>
        <div className="pane1">
          <div className="textPane">
            <div className="TextSMS">
              <span>
                <img alt="logo SMS" src={smsLogo} />
              </span>
              es una organización integrada por Firmas profesionales
              independientes de Auditoría, Consultoría, Contabilidad y
              asesoramiento Fiscal para empresas, que comparten tecnología
              avanzada para prestar los mejores servicios profesionales a
              nuestros clientes.
              <p>
                Al estar integrada por firmas nacionales independientes de alta
                experiencia profesional, somos la única organización en el
                continente que acredita valores latinoamericanos para servir a
                todas las empresas del mundo que radiquen sus operaciones en los
                países de la región y facilitar a las empresas latinoamericanas
                su inserción internacional.
              </p>
              <p>
                A través de nuestra red de firmas hemos logrado generar una
                sólida presencia en toda América Latina y el Caribe, cubriendo
                las necesidades y expectativas de nuestros clientes
              </p>
            </div>
            <a
              className="smsLink botonComun"
              href="https://smslatam.com/"
              target="_blank"
              rel="noreferrer"
            >
              <p> Visita SMS Latinoamérica aquí</p>
            </a>
          </div>
          <a
            href="https://smslatam.com/section/view/123"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="whereSMS" src={smsImage[Index]} />
          </a>
        </div>
      </div>
      <div className="pane2" style={{ marginTop: "0px" }}>
        <div className="textContainer">
          <h2 className="Title">Un jugador activo en la región</h2>
          <p className="Text">Conoce el rol con nuestras alianzas</p>
        </div>
        <div className="TextDelta">
          <span>
            <img alt="logo Delta" src={deltaLogo} />
          </span>
          como miembro de SMS Latinoamérica, cubrimos con servicios
          profesionales las necesidades de nuestros clientes en todo el
          continente. Privilegiamos los conocimientos y las experiencias
          nacionales para resolver las problemáticas y las complejidades de las
          compañías que operan en la región, sea cual fuere su origen
          <p>
            Somos una organización global para la profesión contable, compuesta
            por 180 organizaciones miembros y asociadas en 135 jurisdicciones,
            que representan a millones de contadores profesionales.
          </p>
          <p>
            Basamos nuestro éxito en el conocimiento específico de cada país, su
            entorno y sus empresas, para poder dar siempre la respuesta adecuada
            a cada situación. Esto se logra únicamente perteneciendo y siendo
            parte activa de la región.
          </p>
        </div>
      </div>

      <div className="ifacSection">
        <div className="textContainer">
          <h2 className="Title">Foro de Firmas</h2>
          <div className="Text">El mas grande de Latinoamérica</div>
        </div>
        <div className="pane1">
          <img alt="ifacWorld" src={forumImage[Index]} />
          <div className="textPane">
            <div className="TextSMS">
              <span>
                <img alt="logo forum" src={forodefirmasLogo} />
              </span>
              es una asociación independiente que reúne a redes internacionales
              de firmas de auditoría transnacionales. Su objetivo principal
              radica en fomentar estándares uniformes y de excelencia en la
              elaboración de informes financieros y en las prácticas de
              auditoría a nivel global. En este sentido, el Foro se dedica a
              unir a las firmas que realizan auditorías a nivel transnacional y
              a colaborar de manera estrecha con las actividades del IFAC
              (Consejo Internacional de Normas de Auditoría y Aseguramiento).
              <p>
                Los miembros del Foro deben demostrar su compromiso con la
                adhesión y promoción de la aplicación coherente de prácticas de
                auditoría de alto nivel en todo el mundo, tal como se detalla en
                su Constitución. Delta Consult, a través de su representación en
                SMS Latam, se enorgullece de ser parte de esta prestigiosa
                asociación y de contribuir al fortalecimiento de estándares de
                calidad en el ámbito internaciona
              </p>
            </div>
            <a
              className="ifacLink botonComun"
              href="https://www.ifac.org/"
              target="_blank"
              rel="noreferrer"
            >
              <p>Visita Forum of Firms aquí</p>
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export { SMS };
