import React, { useContext } from "react";
import "./OptionMenu.css";
import { useNavigate } from "react-router-dom";
import { DeltaContext } from "../../../Contexts/DeltaContext";
import { ComponentContext } from "../../../Contexts/ComponentContext";

import arrow from "../../../Assets/Icons/utilIcons/Header_Arrow.svg";
function SubMenu(props) {
  const {
    selectedItem,
    setSelectedContent,
    setShowSubMenu,
    setShowOptionMenu,
    setSelectedItem,
    setSelectedOption,
  } = useContext(DeltaContext);
  const { options } = props;
  const navigate = useNavigate();

  return (
    <div className="subMenu">
      {options
        .find((item) => item.name === selectedItem)
        .links.map((item, index) => (
          <div
            key={`subCompu${index}`}
            onClick={() => {
              setShowSubMenu(false);
              setShowOptionMenu(false);
              setSelectedItem("");
              setSelectedContent(item);
              setSelectedOption("SERVICIOS");
              navigate("/Servicios");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 50);
            }}
          >
            {item}
          </div>
        ))}
    </div>
  );
}

function OptionMenu() {
  const {
    selectedOption,
    showSubMenu,
    setShowSubMenu,
    selectedItem,
    setSelectedItem,
    setSelectedContent,
    setShowOptionMenu,
  } = useContext(DeltaContext);
  const { options } = useContext(ComponentContext);
  const navigate = useNavigate();

  const display = options.find((item) => {
    if (item.title === selectedOption) {
      return item;
    }
    return null;
  });

  return (
    <div className="optionMenuForBig">
      <div className="optionMenu wide">
        <section className="desc">
          <h2>{display.title}</h2>
          <p>{display.text}</p>
        </section>
        <section className="links">
          {display.menu.map((item, index) => {
            return (
              <div
                key={`opcionConSub${index}`}
                onClick={() => {
                  if (showSubMenu && selectedItem === item.name) {
                    setShowSubMenu(false);
                    setSelectedItem("");
                  } else {
                    setShowSubMenu(true);
                    setSelectedItem(item.name);
                  }
                }}
                className={item.name === selectedItem ? "active" : ""}
              >
                <p>{item.name}</p>
                <img src={arrow} alt="arrow" />
              </div>
            );
          })}
          {display.links.map((item, index) => (
            <div
              key={`opcionSinSub${index}`}
              onClick={() => {
                setShowSubMenu(false);
                setShowOptionMenu(false);
                if (selectedOption === "NOSOTROS") {
                  navigate("/Nosotros");
                  setTimeout(function () {
                    const element = document.getElementById(item);
                    if (element) {
                      element.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                        inline: "center",
                      });
                    }
                  }, 50);
                } else if (selectedOption === "INDUSTRIAS") {
                  setSelectedContent(item);
                  navigate("/Industrias");
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 50);
                } else if (selectedOption === "SERVICIOS") {
                  setSelectedContent(item);
                  navigate("/Servicios");
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 50);
                } else if (selectedOption === "MiDELTA") {
                  window.location.href = item.url;
                }
              }}
            >
              {selectedOption === "MiDELTA" ? item.title : item}
            </div>
          ))}
        </section>
        {showSubMenu ? (
          <SubMenu options={display.menu} />
        ) : (
          <div className="filler" />
        )}
      </div>
    </div>
  );
}

export { OptionMenu };
