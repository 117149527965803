import React from "react";
import "./ContactForm.css";

function ContactForm() {
  return (
    <div className="contactForm">
      <div className="sectionText" style={{ borderBottom: "none" }}>
        <h2>Formulario de consulta</h2>
        <p>Te atenderemos lo mas pronto posible</p>
      </div>
      <div className="formContainer">
        <form
          className="contactForm"
          id="FormularioContacto"
          method="post"
          action="/php/mailer/contacto.php"
        >
          <label className="a" htmlFor="nombre">
            <input
              type="name"
              id="nombre"
              placeholder="NOMBRE"
              name="nombre_c"
              required
              pattern="^[a-zA-Z]*$"
            />
          </label>

          <label className="b" htmlFor="mail">
            <input
              type="mail"
              id="mail"
              placeholder="EMAIL"
              name="mail_c"
              required
              pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
            />
          </label>

          <label className="c" htmlFor="asunto">
            <input
              type="text"
              id="asunto"
              placeholder="ASUNTO"
              name="asunto_c"
              required
              pattern="^[\.a-zA-Z0-9,!? ]*$"
            />
          </label>

          <label className="d" htmlFor="mensaje">
            <textarea
              id="mensaje"
              resize="false"
              placeholder="MENSAJE"
              name="mensaje_c"
              required
              pattern="^[\.a-zA-Z0-9,!? ]*$"
            />
          </label>

          <div className="g submitArea">
            <input type="submit" value="ENVIAR" />
          </div>
        </form>
      </div>
    </div>
  );
}

export { ContactForm };
